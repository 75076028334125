import React from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const BadIpContent = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);
    
    return (

        <div className="page">
            <div className="errorText">
                <FontAwesomeIcon icon={faTimesCircle} />
                <div className="">
                    <br />
                    <FormattedMessage id="loginBeenBlocked" />
                    <br /><br />
                    <FormattedMessage id="couldBeBecauseBehaved" />
                    <FormattedMessage id="orUsingVpn" />
                    
                    <br /><br />
                    <FormattedMessage id="ifFeelShouldNotBlocked" />
                </div>
            </div>
        </div>
    )
}

export default BadIpContent

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`